// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.unit__child
{
  background-color: #F5F5F5;
  td:first-child
  {
    padding-left: 20px;
  }
  td:last-child
  {
    padding-right: 20px;
  }
}

.participant__wrap
{
  height: 500px;
  overflow: scroll;
}

body
{
  padding-bottom: 120px;
}

.color--1
{
  color: #4CAE4C;
}

.color--2
{
  color: #85C685;
}

.color--3
{
  color: #F8555F;
}

.color--4
{
  color: #F80000;
}

.navbar-project
{
  margin-top: -20px;
}

//.highcharts-axis-labels
//{
//  span
//  {
//    white-space: normal !important;
//  }
//}

tspan{
  text-decoration:none;
  text-shadow:none !important;
}